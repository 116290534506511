import { Component, Inject, OnInit } from '@angular/core';
import { ProductionService } from '../../services/production.service';
import { SnackbarService } from '../../services/snackbar.service';
import { NavSalesLine } from '../../models/nav-sales-line';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-scheduled-unit-details',
  templateUrl: './scheduled-unit-details.component.html',
  styleUrls: ['./scheduled-unit-details.component.less']
})
export class ScheduledUnitDetailsComponent implements OnInit {

  loading = false;
  partialUnitId: number = -1;
  navSalesLineDataSource: NavSalesLine[] = [];

  constructor(
    private snackBarService: SnackbarService,
    private productionService: ProductionService,
    @Inject(MAT_DIALOG_DATA) public data: {
      partialUnitId: number,
    },
  ) {

    this.partialUnitId = data.partialUnitId;
  }

  displayedColumns: string[] = ['lineNo', 'salesOrderNumber', 'project', 'apartmentNumber', 'productionOrderNumber', 'color', 'style', 'quantity', 'description', 'itemNumber', 'shelfNumber', 'width', 'height', 'weight'];

  ngOnInit() {
    this.getNavSalesLines();
  }

  getData() {
    return this.navSalesLineDataSource;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  async getNavSalesLines() {
    try {
      this.showLoading();
      this.navSalesLineDataSource = await this.productionService.getNavSalesLines(this.partialUnitId).toPromise();
    } catch (err) {
      console.error('Error getting scheduled unit details.', err);
      this.snackBarService.showError('Error getting scheduled unit details.');
    } finally {
      this.hideLoading();
    }
  }
}
