import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductionService } from '../../services/production.service';
import { CartonWithNoFedEx } from '../../models/carton-with-no-fedex';
import { SnackbarService } from '../../services/snackbar.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

const AUTO_REFRESH = 5 * 60 * 1000; // 5 MIN

@Component({
  selector: 'app-what-not-labelled',
  templateUrl: './what-not-labelled.component.html',
  styleUrls: ['./what-not-labelled.component.less']
})
export class WhatNotLabelledComponent implements OnInit, OnDestroy {

  loading = false;
  autoRefreshIntervalId = -1;
  cartonsWithNoFedExDataSource: CartonWithNoFedEx[] = [];

  constructor(
    private snackBarService: SnackbarService,
    private productionService: ProductionService,
  ) { }

  displayedColumns: string[] = ['cartonId', 'cartonNumber', 'cartonType', 'customerName', 'salesOrderNumber', 'projectName', 'apartmentNumber', 'isAccessoriesOnly', 'timeScannedAtStrapper', 'shipMethod'];

  ngOnInit() {
    this.getShippingCartonsWithNoFedExReport();
    this.setAutoRefresh();
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
  }

  getData() {
    return this.cartonsWithNoFedExDataSource;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  toggleAutoRefresh(e: MatSlideToggleChange) {
    clearInterval(this.autoRefreshIntervalId);
    if (e.checked) {
      this.setAutoRefresh();
    }
  }

  setAutoRefresh() {
    // set auto refresh
    this.autoRefreshIntervalId = setInterval(() => {
      this.refresh();
    }, AUTO_REFRESH);
  }

  async refresh() {
    await this.getShippingCartonsWithNoFedExReport();
  }

  async getShippingCartonsWithNoFedExReport() {
    try {
      this.showLoading();
      this.cartonsWithNoFedExDataSource = await this.productionService.getShippingCartonsWithNoFedExReport().toPromise();
    } catch (err) {
      console.error('Error getting shipping cartons with no FedEx:', err);
      this.snackBarService.showError('Error getting shipping cartons with no FedEx.');
    } finally {
      this.hideLoading();
    }
  }
}
