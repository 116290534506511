<h2 mat-dialog-title>Edit Scheduled Unit
  <ng-container *ngIf="isExportedToProduction()">
    <br /><span class="in-production">In Production</span>
  </ng-container>
</h2>
<mat-dialog-content class="mat-dialog-content" [ngClass]="{red: scheduledUnit.isHot}">
  <div class="group">
    <p><strong>Project:</strong> {{scheduledUnit.project}}</p>
    <p><strong>Customer:</strong> {{scheduledUnit.customer}}</p>
  </div>
  <div class="group">
    <p><strong>Apt #:</strong> {{scheduledUnit.apartmentNumber}}</p>
    <p><strong>SO #:</strong> {{scheduledUnit.salesOrderNumber}} </p>
  </div>
  <div class="group">
    <div class="order-type">
      <span class="type hot" *ngIf="scheduledUnit.isHot">H</span>
      <span class="type overnight" *ngIf="scheduledUnit.isOvernight">O</span>
      <span class="type multicolor" *ngIf="scheduledUnit.isMultiColor">M</span>
      <span class="type parts" *ngIf="scheduledUnit.isPartsOrder">P</span>
      <span class="type accessories" *ngIf="scheduledUnit.isAccessories">A</span>
      <span class="type regular" *ngIf="scheduledUnit.isRegularOrder">R</span>
      <span class="type locked-in" *ngIf="scheduledUnit.isLockedIn">L</span>
      <span class="type sample" *ngIf="scheduledUnit.isTestUnit">S</span>
      <span class="type fusion" *ngIf="scheduledUnit.isFusion">F</span>
      <span class="type palletized" *ngIf="scheduledUnit.isPalletized">B</span>
      <span class="type expedited" *ngIf="scheduledUnit.isExpedited">E</span>
    </div>
    <p *ngIf="scheduledUnit.productionOrderNumber">
      <strong>PO #:</strong> {{scheduledUnit.productionOrderNumber}}
    </p>
  </div>
  <div class="group">
    <p><strong>Transit Days:</strong> {{scheduledUnit.transitDays}}</p>
  </div>
  <div class="group">
    <mat-form-field>
      <input matInput [formControl]="productionDate" [matDatepicker]="picker1" placeholder="Production Date"
        (dateChange)="onProductionDateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput [formControl]="requestedShipDate" [matDatepicker]="picker3" placeholder="Requested Date"
        (dateChange)="onRequestedShipDateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="group">
    <mat-form-field>
      <input matInput [formControl]="mustShipDate" [matDatepicker]="picker2" placeholder="No Delivery Before Date"
        (dateChange)="onMustShipDateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput [formControl]="plannedShipDate" [matDatepicker]="picker4" placeholder="Expected Delivery Date"
        (dateChange)="onPlannedShipDateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
      <mat-datepicker #picker4></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="group">
    <mat-form-field>
      <mat-select [formControl]="productionLocation" [(ngModel)]="scheduledUnit.productionLocationId"
        placeholder="Production Location">
        <mat-option *ngFor="let item of productionLocations" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="number">
      <input matInput [formControl]="sequence" type="number" placeholder="Sequence" min="0" required
        (change)="onSequenceChange()">
    </mat-form-field>
  </div>
  <div class="group">
    <mat-form-field>
      <mat-label>Production Status</mat-label>
      <mat-select [formControl]="productionStatus" [(ngModel)]="scheduledUnit.status">
        <mat-option *ngFor="let item of productionStatuses" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Overnight Service</mat-label>
      <mat-select [formControl]="overnightServiceType" [(ngModel)]="scheduledUnit.overnightServiceType">
        <mat-option *ngFor="let item of overnightServiceTypes" [value]="item === serviceTypeStandardShipping ? '' : item">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>    
  </div>
  <div *ngIf="scheduledUnit.productionOrderNumber">
    <mat-checkbox [formControl]="updateAllPartialUnitsRelated" [(ngModel)]="scheduledUnit.updateAllPartialUnitsRelated">
      Update Production Status of every partial units tied to the current PO#
    </mat-checkbox>
  </div>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Shipping Address:</strong>
      </mat-panel-title>
      <mat-panel-description>
        {{format(shippingAddress)}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="group">
      <mat-form-field>
        <input matInput [formControl]="addressLine1" (blur)="onShippingAddressChange()" placeholder="Address Line 1"
          required>
        <mat-error *ngIf="addressLine1?.hasError('required')">
          Address Line 1 is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput [formControl]="addressLine2" (blur)="onShippingAddressChange()" placeholder="Address Line 2">
      </mat-form-field>
    </div>
    <div class="group">
      <mat-form-field>
        <input matInput [formControl]="addressLine3" (blur)="onShippingAddressChange()" placeholder="Address Line 3">
      </mat-form-field>
      <mat-form-field>
        <input matInput [formControl]="zipCode" (blur)="onShippingAddressChange()" placeholder="Zip Code" required>
        <mat-error *ngIf="zipCode?.hasError('required')">
          Zip Code is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="group">
      <mat-form-field>
        <input matInput [formControl]="state" (blur)="onShippingAddressChange()" placeholder="State" required>
        <mat-error *ngIf="state?.hasError('required')">
          State is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput [formControl]="city" (blur)="onShippingAddressChange()" placeholder="City" required>
        <mat-error *ngIf="city?.hasError('required')">
          City is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="group">
      <mat-form-field>
        <input matInput [formControl]="shipToName" (blur)="onShippingAddressChange()" placeholder="Ship to Name">
      </mat-form-field>
      <mat-form-field>
        <input matInput [formControl]="phoneNumber" (blur)="onShippingAddressChange()" placeholder="Phone Number">
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox [formControl]="updateForEntireSalesOrder">
        Update for the entire Sales Order
      </mat-checkbox>
    </div>
  </mat-expansion-panel>
  <mat-form-field class="mt-8">
    <textarea matInput [formControl]="schedulerNotes" placeholder="Scheduler Notes"
      [(ngModel)]="scheduledUnit.schedulerNotes" rows="6"></textarea>
    <button mat-button *ngIf="scheduledUnit.schedulerNotes" matSuffix mat-icon-button aria-label="Clear"
      (click)="scheduledUnit.schedulerNotes=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div class="checkboxes">
    <mat-checkbox [formControl]="isHot" [(ngModel)]="scheduledUnit.isHot"> Hot </mat-checkbox>
    <mat-checkbox [formControl]="isOvernight" [(ngModel)]="scheduledUnit.isOvernight"> Overnight </mat-checkbox>
    <mat-checkbox [formControl]="isLockedIn" [(ngModel)]="scheduledUnit.isLockedIn"> LockedIn </mat-checkbox>
    <mat-checkbox [formControl]="isAudit" [(ngModel)]="scheduledUnit.isAudit"> Audit </mat-checkbox>
    <mat-checkbox [formControl]="isPalletized" [(ngModel)]="scheduledUnit.isPalletized"> Palletized </mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="accent" [disabled]="!enableUpdateScheduledUnit"
    (click)="updateScheduledUnit()">Update</button>
</mat-dialog-actions>