import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductionPartsNotCartoned } from '../models/production-parts-not-cartoned';
import { CartonWithNoFedEx } from '../models/carton-with-no-fedex';
import { ConfigService } from './config.service';
import { NavSalesLine } from '../models/nav-sales-line';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getProductionOrdersNotCartoned(): Observable<ProductionPartsNotCartoned[]> {
    const url = this.config.buildURL(`production/getProductionPartsLockedInNotCartonedReport`);
    return this.http.get<ProductionPartsNotCartoned[]>(url);
  }

  getWhatsNotScheduledByDate(): Observable<ProductionPartsNotCartoned[]> {
    const url = this.config.buildURL(`production/whatsNotScheduledByDate`);
    return this.http.get<ProductionPartsNotCartoned[]>(url);
  }

  getShippingCartonsWithNoFedExReport(): Observable<CartonWithNoFedEx[]> {
    const url = this.config.buildURL(`production/getShippingCartonsWithNoFedExReport`);
    return this.http.get<CartonWithNoFedEx[]>(url);
  }

  getNavSalesLines(partialUnitId: number): Observable<NavSalesLine[]> {
    const url = this.config.buildURL(`production/getNavSalesLines/${partialUnitId}`);
    return this.http.get<NavSalesLine[]>(url);
  }
}
