import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { User } from '../../../models/user';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-cl-update-user-modal',
  templateUrl: './cl-update-user-modal.component.html',
  styleUrls: ['./cl-update-user-modal.component.less']
})
export class ClUpdateUserModalComponent {

  public _customerInfoService: CustomerService;
  public userResponse: any;
  public user: User = {
    userEmail: '',
    customerNumber: '',
    password: ''
  };

  updateUserForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ClUpdateUserModalComponent>,
    private formBuilder: FormBuilder,
    private customerInfoService: CustomerService,
    private snackBarService: SnackbarService,) {
    this.createForm();
    this._customerInfoService = customerInfoService;
  }

  closeWithOutSaving() {
    this.dialogRef.close();
  }

  async submit() {
    try {
      this.userResponse = await this._customerInfoService.updatePasswordUser(this.user).toPromise()
      if (this.userResponse.isValid) {
        if (this.userResponse.message != "") {
          this.snackBarService.showError(this.userResponse.message);
        }
        this.snackBarService.showInfo(`User ${this.user.userEmail} updated.`);
      } else {
        this.snackBarService.showSuccess(this.userResponse.message);
      }
    } catch (error) {
      this.snackBarService.showError(error);
    }
  }

  createForm() {
    this.updateUserForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }
}
