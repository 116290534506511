import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FormattedScheduledUnit } from '../../models/formatted-scheduled-unit';
import { ProductionLocation } from '../../models/production-location';
import { ScheduledUnit } from '../../models/scheduled-unit';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import {
  BottomSheetScheduleLegendComponent,
} from '../shared/bottom-sheet-schedule-legend/bottom-sheet-schedule-legend.component';
import {
  SchedulerNotesDetailsComponent,
} from '../schedule-manager/scheduler-notes-details/scheduler-notes-details.component';
import { EditScheduledUnitComponent } from '../schedule-manager/edit-scheduled-unit/edit-scheduled-unit.component';
import { Color } from '../../models/color';
import { DoorStyle } from '../../models/door-style';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ProductionCalendar } from '../../models/production-calendar';

const INVALID_LOCATION_ID = -1;
const LOADING_ANIMATION_TIMEOUT = 500; // ms
const STANDARD_SLAB_PART = 'standard slab';
const AUTO_REFRESH = 2 * 60 * 1000; // 2 MIN
const SHOW_ALL = 'show-all';

@Component({
  selector: 'app-schedule-look-ahead',
  templateUrl: './schedule-look-ahead.component.html',
  styleUrls: ['./schedule-look-ahead.component.less']
})
export class ScheduleLookAheadComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = false;
  mousedown = false;
  autoRefresh = true;
  fullScreenMode = false;
  customPartsCapacity = -1;
  allowedCustomPartsCapacity = -1;
  productionCalendar: ProductionCalendar;
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  shipMethods: string[] = [];

  displayedColumns: string[] = ['productionDate', 'productionOrderNumber', 'customer', 'customerNumber', 'salesOrderNumber', 'project', 'apartmentNumber', 'color', 'style', 'numberOfParts', 'partialUnitId', 'schedulerNotes', 'overnightServiceType',
    'materialSupplier', 'sequence', 'stackRank', 'friendlyStatus', 'audit', 'productionLocationId', 'orderDate', 'requestedDeliveryDate', 'mustShipDate', 'expedited', 'plannedShipDate', 'transitDays', 'fedExTransitDays', 'shipMethod'];

  // filters
  filtersApplied = false;
  filterOptions = ['Hot / Overnight', 'Multi Color', 'Parts', 'Accessories', 'Regular', 'LockedIn', 'Sample', 'No Capacity Found'];

  projectFilter = new FormControl();
  customerFilter = new FormControl();
  salesOrderNumberFilter = new FormControl();
  orderDateFilter = new FormControl();
  productionDateFilter = new FormControl();
  mustShipDateFilter = new FormControl();
  requestedShipDateFilter = new FormControl();
  colorFilter = new FormControl([]);
  doorStyleFilter = new FormControl([]);
  shipMethodFilter = new FormControl([]);
  apartmentFilter = new FormControl();

  hotFilter = false;
  overnightFilter = false;
  multicolorFilter = false;
  partsFilter = false;
  accessoriesFilter = false;
  noAccessoriesFilter = false;
  regularFilter = false;
  lockedInFilter = false;
  noLockedInFilter = false;
  sampleFilter = false;
  retailFilter = false;
  palletFilter = false;
  fusionFilter = false;
  auditFilter = false;
  expeditedFilter = false;
  noExpeditedFilter = false;

  maxSelectedIndex = -1;
  minSelectedIndex = -1;
  autoRefreshIntervalId = -1;
  scheduledUnitsSelection = new SelectionModel<number>(true, []);
  scheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  filteredScheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  editFormPanelDisplaced = true;
  filterFormPanelDisplaced = true;
  totalCustomPartsSelected = 0;
  totalSlabPartsSelected = 0;
  totalShakerPartsSelected = 0;
  totalAccessoriesSelected = 0;

  scheduledUnitsSort: MatSort;
  lastScheduledUnitsSortState: Sort;

  @ViewChild('scheduledUnitsSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.scheduledUnitsSort = ms;
    this.ngAfterViewInit();

    // trigger sort change
    if (this.scheduledUnitsSort && this.lastScheduledUnitsSortState) {
      setTimeout(() => {
        this.scheduledUnitsSort.active = this.lastScheduledUnitsSortState.active;
        this.scheduledUnitsSort.direction = this.lastScheduledUnitsSortState.direction;
        this.scheduledUnitsSort.sortChange.emit(this.lastScheduledUnitsSortState);
      });
    }
  }

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private bottomSheetScheduleLegend: MatBottomSheet,
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    try {
      this.showLoading();
      this.fullScreenMode = this.getFullScreenMode();
      this.getColors();
      this.getDoorStyles();
      this.getShipMethods();
      await this.getProductionLocations();
      await this.setProductionCalendar();
      await this.suggestSchedule();
      this.setAutoRefresh();
    } catch (err) {
      console.error('Error loading scheduled units:', err);
    } finally {
      this.hideLoading();
    }
  }

  setAutoRefresh() {
    clearInterval(this.autoRefreshIntervalId);
    // set auto refresh
    this.autoRefreshIntervalId = setInterval(async () => {
      await this.refresh();
    }, AUTO_REFRESH);
  }

  async setProductionCalendar() {
    try {
      this.productionCalendar = await this.productionCalendarService.getNextProductionCalendar(this.productionLocationId).toPromise();
    } catch (err) {
      console.error('Error getting production calendar:', err);
      this.productionCalendar = null;
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
    this.scheduledUnitsDataSource.disconnect();
    this.scheduledUnitsDataSource.data.length = 0;
  }

  scheduledUnitsSortChange(e: Sort) {
    this.lastScheduledUnitsSortState = e;
  }

  toggleAutoRefresh(e: MatSlideToggleChange) {
    clearInterval(this.autoRefreshIntervalId);
    this.autoRefresh = e.checked;
    if (this.autoRefresh) {
      this.setAutoRefresh();
    }
  }

  async refresh() {
    await this.suggestSchedule();
    this.applyFilters();
  }

  getDisplaced(): string {
    if (this.editFormPanelDisplaced && this.filterFormPanelDisplaced) { return 'displaced-3'; }
    if (this.editFormPanelDisplaced) { return 'displaced-2'; }
    if (this.filterFormPanelDisplaced) { return 'displaced-1'; }
    return '';
  }

  ngAfterViewInit() {
    // sort options
    this.scheduledUnitsDataSource.sort = this.scheduledUnitsSort;
    this.filteredScheduledUnitsDataSource.sort = this.scheduledUnitsSort;
  }

  async suggestSchedule(): Promise<void> {
    if (this.productionCalendar) {
      try {
        this.clearDataSource();
        this.showLoading();
        const scheduledUnits = await this.scheduleService.getScheduleLookAhead(this.productionCalendar.productionLocationId, this.productionCalendar.productionDate).toPromise();
        this.scheduledUnitsDataSource.data = scheduledUnits;
      } catch (err) {
        console.error(err);
        this.snackBarService.showError('Error getting must ship tomorrow orders.');
      } finally {
        this.updateScheduledUnitsSummary();
        this.hideLoading();
      }
    } else {
      this.snackBarService.showError('Please select a production location.');
    }
  }

  openBottomSheetScheduleLegend() {
    this.bottomSheetScheduleLegend.open(BottomSheetScheduleLegendComponent);
  }

  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  onMousedown(event, index) {
    if (!event.shiftKey && !event.ctrlKey) {
      this.mousedown = true;
      this.minSelectedIndex = index;
    }
  }

  onMouseenter(event, index) {
    if (this.mousedown) {
      this.selectRow(index);
    }
  }

  onMouseleave(event, index) {
    if (this.mousedown) {
      this.selectRow(index);
    }
  }

  onMouseup(event, index) {
    if (!event.shiftKey && !event.ctrlKey && this.mousedown) {
      this.maxSelectedIndex = index;
      const minIndex = Math.min(this.minSelectedIndex, this.maxSelectedIndex);
      const maxIndex = Math.max(this.minSelectedIndex, this.maxSelectedIndex);

      this.minSelectedIndex = minIndex;
      this.maxSelectedIndex = maxIndex;

      if (this.minSelectedIndex !== this.maxSelectedIndex) {
        this.selectRows(this.minSelectedIndex, this.maxSelectedIndex);
      }
      this.clearSelectedIndexes();
    }
  }

  onLockedInFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noLockedInFilter) {
      this.noLockedInFilter = false;
    }
  }

  onNoLockedInFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.lockedInFilter) {
      this.lockedInFilter = false;
    }
  }

  onExpeditedFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noExpeditedFilter) {
      this.noExpeditedFilter = false;
    }
  }

  onNoExpeditedFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.expeditedFilter) {
      this.expeditedFilter = false;
    }
  }

  clearSelectedIndexes() {
    this.mousedown = false;
    this.minSelectedIndex = -1;
    this.maxSelectedIndex = -1;
  }

  clearSelection() {
    this.scheduledUnitsSelection.clear();
    this.clearScheduledUnitsSummary();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  onScheduledUnitsTableRowClick(event, index) {
    if (event.shiftKey) {
      this.minSelectedIndex = this.minSelectedIndex === -1 ? index : this.minSelectedIndex;
      this.maxSelectedIndex = this.maxSelectedIndex === -1 ? index : this.maxSelectedIndex;

      this.minSelectedIndex = Math.min(this.minSelectedIndex, index);
      this.maxSelectedIndex = Math.max(this.maxSelectedIndex, index);

      this.selectRows(this.minSelectedIndex, this.maxSelectedIndex);
    }

    if (event.ctrlKey) {
      this.toggleRow(index);
    }
  }

  selectRows(start: number, end: number) {
    this.scheduledUnitsSelection.clear();

    this.clearScheduledUnitsSummary();

    for (let i = start; i <= end; i++) {
      this.selectRow(i);
    }
  }

  selectRow(index: number) {
    const data = this.getData();
    const row = data[index];
    if (row) {
      this.increaseSelectedScheduledUnitsSummary(row);
      this.scheduledUnitsSelection.select(row.partialUnitId);
    }
  }

  increaseSelectedScheduledUnitsSummary(item: FormattedScheduledUnit) {
    if (item.isAccessories) {
      this.addAccessories(item.numberOfParts);
    } else {
      this.addCustomParts(item);
    }
  }

  decreaseSelectedScheduledUnitsSummary(item: FormattedScheduledUnit) {
    if (item.isAccessories) {
      this.removeAccessories(item.numberOfParts);
    } else {
      this.removeCustomParts(item);
    }
  }

  toggleScheduledUnitsSelection(event: MatCheckboxChange, element: FormattedScheduledUnit) {
    if (event.checked) {
      this.increaseSelectedScheduledUnitsSummary(element);
      this.scheduledUnitsSelection.select(element.partialUnitId);
    } else {
      this.scheduledUnitsSelection.deselect(element.partialUnitId);
      this.decreaseSelectedScheduledUnitsSummary(element);
    }
  }

  onScheduledUnitsTableDblClick(event) {
    const partialUnitId = this.findAttributeScheduledUnitId(event);
    if (partialUnitId) {
      this.openEditScheduledUnit(partialUnitId);
    }
  }

  openEditScheduledUnit(partialUnitId: number) {
    const scheduledUnit = this.findScheduledUnit(partialUnitId);
    if (scheduledUnit) {
      const dialogRef = this.dialog.open(EditScheduledUnitComponent, {
        width: '780px',
        data: {
          scheduledUnit,
          productionLocations: this.productionLocations,
        }
      });

      dialogRef.afterOpened().subscribe(() => {
        this.applyFilterByPartialUnitId(partialUnitId);
      });

      dialogRef.afterClosed().subscribe((result: FormattedScheduledUnit) => {
        if (result) {
          this.refreshDataSource(scheduledUnit, result);
          this.snackBarService.showSuccess('The scheduled unit information was updated successfully.');
        }
        setTimeout(() => {
          this.resetFilterByPartialUnitId();
          this.applyFilters();
        }, 0);
      });
    }
  }

  isScheduledUnit(item: FormattedScheduledUnit): item is FormattedScheduledUnit {
    return (item as FormattedScheduledUnit).color !== undefined;
  }

  isSlabPart(item: FormattedScheduledUnit): boolean {
    return this.isScheduledUnit(item) && item.style.toLowerCase() === STANDARD_SLAB_PART;
  }

  isShakerPart(item: FormattedScheduledUnit): boolean {
    return this.isScheduledUnit(item) && item.style.toLowerCase() !== '' && item.style.toLowerCase() !== STANDARD_SLAB_PART;
  }

  addCustomParts(item: FormattedScheduledUnit) {
    this.totalSlabPartsSelected += this.isSlabPart(item) ? item.numberOfParts : 0;
    this.totalShakerPartsSelected += this.isShakerPart(item) ? item.numberOfParts : 0;
    this.totalCustomPartsSelected += item.numberOfParts;
  }

  addAccessories(totalAccessories: number) {
    this.totalAccessoriesSelected += totalAccessories;
  }

  removeCustomParts(item: FormattedScheduledUnit) {
    this.totalSlabPartsSelected -= this.isSlabPart(item) ? item.numberOfParts : 0;
    this.totalShakerPartsSelected -= this.isShakerPart(item) ? item.numberOfParts : 0;
    this.totalCustomPartsSelected -= item.numberOfParts;
  }

  removeAccessories(totalAccessories: number) {
    this.totalAccessoriesSelected -= totalAccessories;
  }

  clearScheduledUnitsSummary() {
    this.totalCustomPartsSelected = 0;
    this.totalAccessoriesSelected = 0;
    this.totalSlabPartsSelected = 0;
    this.totalShakerPartsSelected = 0;
  }

  toggleRow(index: number) {
    const data = this.getData();
    const row = data[index];

    if (row) {
      this.scheduledUnitsSelection.toggle(row.partialUnitId);

      if (this.scheduledUnitsSelection.isSelected(row.partialUnitId)) {
        this.increaseSelectedScheduledUnitsSummary(row);
      } else {
        this.decreaseSelectedScheduledUnitsSummary(row);
      }
    }
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  getShipMethods() {
    this.scheduleService.getShipMethods().subscribe((shipMethods: string[]) => {
      this.shipMethods = shipMethods;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting ship methods.');
    });
  }

  getDoorStyles() {
    this.productionCalendarService.getDoorStyles().subscribe((doorStyles: DoorStyle[]) => {
      this.doorStyles = doorStyles;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    });
  }

  getData(): FormattedScheduledUnit[] {
    return this.getScheduledUnitsDataSource().connect().value;
  }

  onScheduledUnitsTableClick(event) {
    let schedulerNotes = '';
    const partialUnitId = this.findAttributeScheduledUnitId(event);
    if ((event.target.tagName === 'P' && event.target.className.includes('notes')) && partialUnitId) {
      schedulerNotes = this.findScheduledUnit(partialUnitId).schedulerNotes;
    }
    if (schedulerNotes) {
      this.openSchedulerNotesDetailsModal(schedulerNotes);
    }
  }

  getSelectedScheduledUnitsIds(): number[] {
    return this.scheduledUnitsSelection.selected;
  }

  onLegendPanelOpened() {
    this.filterFormPanelDisplaced = true;
  }

  onLegendPanelClosed() {
    this.filterFormPanelDisplaced = false;
  }

  onFiltersPanelOpened() {
    this.editFormPanelDisplaced = true;
  }

  onFiltersPanelClosed() {
    this.editFormPanelDisplaced = false;
  }

  findAttributeScheduledUnitId(event): number {
    let schedUnitId = 0;
    const path = event.path || (event.composedPath && event.composedPath()) || [];
    for (let i = 0; i < path.length; i++) {
      const el = path[i];
      if (el.tagName === 'TR' && el.getAttribute('sched-unit-id')) {
        schedUnitId = parseInt(el.getAttribute('sched-unit-id'), 10);
        break;
      }
    }
    return schedUnitId;
  }

  findAttributeSalesOrderNumber(event): string {
    let soNumber = '';
    const path = event.path || (event.composedPath && event.composedPath()) || [];
    for (let i = 0; i < path.length; i++) {
      const el = path[i];
      if (el.tagName === 'TR' && el.getAttribute('sales-order-number')) {
        soNumber = el.getAttribute('sales-order-number');
        break;
      }
    }
    return soNumber;
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }

  filterByHot(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isHot);
  }

  filterByOvernight(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isOvernight);
  }

  filterByPartsOrders(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isPartsOrder);
  }

  filterByAccessories(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isAccessories);
  }

  filterByNoAccessories(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.isAccessories);
  }

  filterByMultiColors(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isMultiColor);
  }

  filterByLockedIn(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isLockedIn);
  }

  filterByNoLockedIn(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.isLockedIn);
  }

  filterByJIT(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.jit);
  }

  filterByNoJIT(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.jit);
  }

  filterByAudit(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isAudit);
  }

  filterByTestUnits(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isTestUnit);
  }

  filterByRegularOrders(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isRegularOrder);
  }

  filterByProject() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.project.toLowerCase().includes(this.projectFilter.value.toLowerCase()));
  }

  filterByCustomer() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.customer.toLowerCase().includes(this.customerFilter.value.toLowerCase()));
  }

  filterByColor() {
    const result = [];
    const colors = this.colorFilter.value.filter(c => c);
    for (const color of colors) {
      if (color === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.color === color);
      result.push(...data);
    }
    return result;
  }

  filterByDoorStyle() {
    const result = [];
    const doorStyles = this.doorStyleFilter.value.filter(c => c);
    for (const doorStyle of doorStyles) {
      if (doorStyle === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.style === doorStyle);
      result.push(...data);
    }
    return result;
  }

  filterByShipMethod() {
    const result = [];
    const methods = this.shipMethodFilter.value.filter(c => c);
    for (const method of methods) {
      if (method === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.shipMethod === method);
      result.push(...data);
    }
    return result;
  }

  filterBySONumber() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.salesOrderNumber.toLowerCase()
      .includes(this.salesOrderNumberFilter.value.toLowerCase()));
  }

  filterByNoCapacityFound() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.productionDate);
  }

  filterByHasPONumber() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.productionOrderNumber);
  }

  filterByNoHasPONumber() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.productionOrderNumber);
  }

  filterByPartialUnitId(partialUnitId: number) {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.partialUnitId === partialUnitId);
  }

  filterByApartment() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.apartmentNumber.toLowerCase().includes(this.apartmentFilter.value.toLowerCase()));
  }

  equals(date1: string | Date, date2: string | Date): boolean {
    const a = new Date(date1);
    const b = new Date(date2);
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  filterByOrderDate() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.productionDate && this.equals(this.orderDateFilter.value, u.orderDate));
  }

  filterByProductionDate() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.productionDate && this.equals(this.productionDateFilter.value, u.productionDate));
  }

  filterByMustShipDate() {
    // eslint-disable-next-line max-len
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.mustShipDate && this.equals(this.mustShipDateFilter.value, u.mustShipDate));
  }

  filterByRequestedShipDate() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.requestedShipDate
      && this.equals(this.requestedShipDateFilter.value, u.requestedShipDate));
  }

  filterByRetail(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isRetail);
  }

  filterByFusion(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isFusion);
  }

  filterByPallet(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isPalletized);
  }

  onMultipleSelectionChange(e: MatSelectChange) {
    for (const value of e.value) {
      if (value === SHOW_ALL) {
        e.source.value = [SHOW_ALL];
        break;
      }
    }
  }

  filterByExpedited(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isExpedited);
  }

  filterByNoExpedited(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.isExpedited);
  }

  async onProductionLocationChange(event: MatSelectChange) {
    this.productionLocationId = event.value;
    await this.setProductionCalendar();
    await this.refresh();
    if (this.autoRefresh) {
      this.setAutoRefresh();
    }
  }

  onOrderDateFilterChange() {
    if (!this.orderDateFilter.valid) {
      this.snackBarService.showWarning('Invalid order date.');
      this.orderDateFilter.setValue('');
    }
  }

  onProductionDateFilterChange() {
    if (!this.productionDateFilter.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDateFilter.setValue('');
    }
  }

  onMustShipDateFilterChange() {
    if (!this.mustShipDateFilter.valid) {
      this.snackBarService.showWarning('Invalid must ship date.');
      this.mustShipDateFilter.setValue('');
    }
  }

  onRequestedShipDateFilterChange() {
    if (!this.requestedShipDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested ship date.');
      this.requestedShipDateFilter.setValue('');
    }
  }

  needToDisplayMaterialSupplier(e: FormattedScheduledUnit): boolean {
    const colors: string[] = ['Flour', 'Mist'];
    return e.materialSupplierColor && colors.indexOf(e.color) !== -1;
  }

  getScheduledUnitsDataSource(): MatTableDataSource<FormattedScheduledUnit> {
    return this.filtersApplied ? this.filteredScheduledUnitsDataSource : this.scheduledUnitsDataSource;
  }

  applyFilters() {
    this.showLoading();

    this.filteredScheduledUnitsDataSource.data = this.scheduledUnitsDataSource.data;
    this.clearSelection();

    if (this.customerFilter.valid && this.customerFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByCustomer();
      this.filtersApplied = true;
    }
    if (this.salesOrderNumberFilter.valid && this.salesOrderNumberFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterBySONumber();
      this.filtersApplied = true;
    }
    if (this.projectFilter.valid && this.projectFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByProject();
      this.filtersApplied = true;
    }
    if (this.apartmentFilter.valid && this.apartmentFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByApartment();
      this.filtersApplied = true;
    }
    if (this.colorFilter.valid && this.colorFilter.value.length > 0) {
      this.filteredScheduledUnitsDataSource.data = this.filterByColor();
      this.filtersApplied = true;
    }
    if (this.doorStyleFilter.valid && this.doorStyleFilter.value.length > 0) {
      this.filteredScheduledUnitsDataSource.data = this.filterByDoorStyle();
      this.filtersApplied = true;
    }
    if (this.orderDateFilter.valid && this.orderDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByOrderDate();
      this.filtersApplied = true;
    }
    if (this.productionDateFilter.valid && this.productionDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByProductionDate();
      this.filtersApplied = true;
    }
    if (this.requestedShipDateFilter.valid && this.requestedShipDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByRequestedShipDate();
      this.filtersApplied = true;
    }
    if (this.mustShipDateFilter.valid && this.mustShipDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByMustShipDate();
      this.filtersApplied = true;
    }
    if (this.shipMethodFilter.valid && this.shipMethodFilter.value.length > 0) {
      this.filteredScheduledUnitsDataSource.data = this.filterByShipMethod();
      this.filtersApplied = true;
    }
    if (this.hotFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByHot();
      this.filtersApplied = true;
    }
    if (this.overnightFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByOvernight();
      this.filtersApplied = true;
    }
    if (this.multicolorFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByMultiColors();
      this.filtersApplied = true;
    }
    if (this.partsFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByPartsOrders();
      this.filtersApplied = true;
    }
    if (this.accessoriesFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByAccessories();
      this.filtersApplied = true;
    }
    if (this.noAccessoriesFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoAccessories();
      this.filtersApplied = true;
    }
    if (this.regularFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByRegularOrders();
      this.filtersApplied = true;
    }
    if (this.lockedInFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByLockedIn();
      this.filtersApplied = true;
    }
    if (this.noLockedInFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoLockedIn();
      this.filtersApplied = true;
    }
    if (this.sampleFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByTestUnits();
      this.filtersApplied = true;
    }
    if (this.retailFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByRetail();
      this.filtersApplied = true;
    }
    if (this.fusionFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByFusion();
      this.filtersApplied = true;
    }
    if (this.palletFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByPallet();
      this.filtersApplied = true;
    }
    if (this.auditFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByAudit();
      this.filtersApplied = true;
    }
    if (this.expeditedFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByExpedited()
      this.filtersApplied = true;
    }
    if (this.noExpeditedFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoExpedited()
      this.filtersApplied = true;
    }
    setTimeout(() => {
      this.hideLoading();
      this.updateScheduledUnitsSummary();
    }, LOADING_ANIMATION_TIMEOUT);
  }

  toggleAccessoriesFilter(e: MatCheckboxChange) {
    this.noAccessoriesFilter = this.noAccessoriesFilter && !e.checked;
  }

  toggleNoAccessoriesFilter(e: MatCheckboxChange) {
    this.accessoriesFilter = this.accessoriesFilter && !e.checked;
  }

  updateScheduledUnitsSummary(): void {
    this.clearScheduledUnitsSummary();
    const data = this.getData();

    for (const item of data) {
      if (item.isAccessories) {
        this.addAccessories(item.numberOfParts);
      } else {
        this.addCustomParts(item);
      }
    }
  }

  findScheduledUnit(partialUnitId: number): FormattedScheduledUnit {
    return this.scheduledUnitsDataSource.data.find(u => u.partialUnitId === partialUnitId);
  }

  findScheduledUnitsByUniqueUnitId(schedUnit: ScheduledUnit): FormattedScheduledUnit[] {
    return this.scheduledUnitsDataSource.data.filter(c => c.uniqueUnitId === schedUnit.uniqueUnitId);
  }

  findScheduledUnitsByProductionOrderNumber(schedUnit: ScheduledUnit): FormattedScheduledUnit[] {
    return this.scheduledUnitsDataSource.data.filter(c => c.productionOrderNumber === schedUnit.productionOrderNumber);
  }

  applyFilterByPartialUnitId(partialUnitId: number) {
    this.filteredScheduledUnitsDataSource.data = this.scheduledUnitsDataSource.data;
    this.filteredScheduledUnitsDataSource.data = this.filterByPartialUnitId(partialUnitId);
    this.filtersApplied = true;
  }

  resetFilterByPartialUnitId() {
    this.filtersApplied = false;
    this.filteredScheduledUnitsDataSource.data = [];
  }

  refreshDataSource(prevScheduledUnit: FormattedScheduledUnit, result: FormattedScheduledUnit) {
    const scheduledUnits = this.findScheduledUnitsByUniqueUnitId(result);
    // update scheduled units by unique unit id
    for (const unit of scheduledUnits) {
      unit.isLockedIn = result.isLockedIn;
      unit.isHot = result.isHot;
      unit.isOvernight = result.isOvernight;
      unit.overnightServiceType = result.overnightServiceType;
      unit.productionLocationId = result.productionLocationId;
      unit.productionLocationStr = result.productionLocationStr;
      unit.orderDate = result.orderDate;
      unit.orderDateStr = result.orderDateStr;
      unit.productionDate = result.productionDate;
      unit.productionDateStr = result.productionDateStr;
      unit.plannedShipDate = result.plannedShipDate;
      unit.plannedShipDateStr = result.plannedShipDateStr;
      unit.requestedShipDate = result.requestedShipDate;
      unit.requestedShipDateStr = result.requestedShipDateStr;
      unit.mustShipDate = result.mustShipDate;
      unit.mustShipDateStr = result.mustShipDateStr;
      unit.isAudit = result.isAudit;
      unit.isAuditStr = result.isAuditStr;
      unit.isRetail = result.isRetail;
      unit.isPalletized = result.isPalletized;
      unit.isPartsOrder = result.isPartsOrder;
    }
    // update scheduled units by PO#
    if (result.productionOrderNumber && result.updateAllPartialUnitsRelated) {
      const relatedPartialUnits = this.findScheduledUnitsByProductionOrderNumber(result);
      for (const unit of relatedPartialUnits) {
        unit.status = result.status;
        unit.friendlyStatus = result.friendlyStatus;
      }
    }
    // update prev
    prevScheduledUnit.sequence = result.sequence;
    prevScheduledUnit.schedulerNotes = result.schedulerNotes;
    prevScheduledUnit.classType = result.classType;
    prevScheduledUnit.status = result.status;
    prevScheduledUnit.friendlyStatus = result.friendlyStatus;
    prevScheduledUnit.isPalletized = result.isPalletized;
    prevScheduledUnit.isPartsOrder = result.isPartsOrder;
  }

  openSchedulerNotesDetailsModal(schedulerNotes: string) {
    this.dialog.open(SchedulerNotesDetailsComponent, {
      width: '480px',
      data: { schedulerNotes }
    });
  }

  clearFilters() {
    this.projectFilter.setValue('');
    this.customerFilter.setValue('');
    this.salesOrderNumberFilter.setValue('');
    this.colorFilter.setValue([]);
    this.doorStyleFilter.setValue([]);
    this.orderDateFilter.setValue('');
    this.productionDateFilter.setValue('');
    this.requestedShipDateFilter.setValue('');
    this.mustShipDateFilter.setValue('');
    this.apartmentFilter.setValue('');
    this.shipMethodFilter.setValue([]);
    this.hotFilter = false;
    this.overnightFilter = false;
    this.multicolorFilter = false;
    this.partsFilter = false;
    this.accessoriesFilter = false;
    this.noAccessoriesFilter = false;
    this.regularFilter = false;
    this.lockedInFilter = false;
    this.noLockedInFilter = false;
    this.sampleFilter = false;
    this.retailFilter = false;
    this.fusionFilter = false;
    this.palletFilter = false;
    this.auditFilter = false;
    this.expeditedFilter = false;
    this.noExpeditedFilter = false;
    this.filteredScheduledUnitsDataSource.data = [];
    this.clearScheduledUnitsSummary();
    this.filtersApplied = false;
  }

  clearDataSource() {
    this.scheduledUnitsDataSource.data = [];
    this.filteredScheduledUnitsDataSource.data = [];
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
}
