import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackbar.service';
import { BaseForm } from '../../forms/base-form';
import { SignIn } from '../../models/sign-in';
import { JsonWebToken } from '../../models/json-web-token';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.less']
})
export class SignInComponent extends BaseForm implements OnInit, AfterViewInit {

  appVersion = '0.0.0';
  signInForm: UntypedFormGroup;
  date: Date = new Date();
  year: number = this.date.getFullYear();
  // snow animation
  showAnimations = (this.date.getMonth() === 10 && this.date.getDate() >= 15) || this.date.getMonth() === 11 || (this.date.getMonth() === 0 && this.date.getDate() <= 15);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private config: ConfigService,
    private authService: AuthService,
    private snackBarService: SnackbarService) {
    super();
    this.createForm();
  }

  ngOnInit() {

    this.appVersion = this.config.getAppVersion();

    if (this.authService.isSessionExpired()) {
      this.authService.clearAccessToken();
    }
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngAfterViewInit() {
    const message = this.route.snapshot.queryParamMap.get('message');
    const machine = this.route.snapshot.queryParamMap.get('machine');
    const routerId = +this.route.snapshot.queryParamMap.get('routerId');

    if (message) {
      setTimeout(() => {
        this.snackBarService.showError(message);
      });
    }

    if (machine) {
      this.authService.setMachine(machine);
    }

    if (routerId) {
      this.authService.setRouter(routerId);
    }
  }

  createForm() {
    this.signInForm = this.fb.group({
      username: [''],
      password: ['']
    });
  }

  resetValues() {
    this.signInForm.setValue({
      username: '',
      password: '',
    });
  }

  isValid(): boolean {
    const username = this.signInForm.controls.username.value.toString().trim();
    const password = this.signInForm.controls.password.value.toString().trim();

    return username !== '' && password !== '';
  }

  submit() {
    this.reset();

    if (this.isValid()) {
      return this.signIn();
    }

    this.errorMessages = ['Invalid username or password.'];
    this.resetValues();
  }

  getUsernameErrorMessage() {
    return this.signInForm.controls.username.hasError('required') ? 'Invalid username' : '';
  }

  getPasswordErrorMessage() {
    return this.signInForm.controls.password.hasError('required') ? 'Invalid password' : '';
  }

  getSymbol(): string {
    return '❅';
  }

  signIn() {
    const model: SignIn = {
      username: this.signInForm.controls.username.value,
      password: this.signInForm.controls.password.value
    };

    this.authService.signIn(model).subscribe((token: JsonWebToken) => {
      this.authService.setAccessToken(token);
      this.router.navigate(['/dashboard']);
    },
      err => {
        console.error(err);
        this.errorMessages = this.getErrorMessages(err);
        this.snackBarService.showError('Sign in failed! Please try again.');
        this.resetValues();
      });
  }
}
