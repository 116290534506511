<h2 mat-dialog-title> Scheduled Unit Details</h2>
<mat-dialog-content>
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <h3 *ngIf="!loading && getData().length === 0"> No nav sales lines to show.</h3>

    <table *ngIf="!loading && getData().length > 0" mat-table [dataSource]="getData()" class="mat-elevation-z0 table">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="lineNo">
            <th mat-header-cell *matHeaderCellDef> Line # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.lineNo}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="salesOrderNumber">
            <th mat-header-cell *matHeaderCellDef> SO # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.salesOrderNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="project">
            <th mat-header-cell *matHeaderCellDef> Project </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.project}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="apartmentNumber">
            <th mat-header-cell *matHeaderCellDef> Apt # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.apartmentNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="productionOrderNumber">
            <th mat-header-cell *matHeaderCellDef> PO # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.productionOrderNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef> Color </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.color}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="style">
            <th mat-header-cell *matHeaderCellDef> Style </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.style}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Parts </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.quantity}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.description}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="itemNumber">
            <th mat-header-cell *matHeaderCellDef> Item # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.itemNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="shelfNumber">
            <th mat-header-cell *matHeaderCellDef> Shelf # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.shelfNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="width">
            <th mat-header-cell *matHeaderCellDef> Width </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.width}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="height">
            <th mat-header-cell *matHeaderCellDef> Height </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.height}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Weight </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.weight}}</span>
            </td>
        </ng-container>
    </table>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>