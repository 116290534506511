<mat-card>
    <h1 class="mat-h1">
        What's Not in the Truck

        <div>
            <mat-slide-toggle checked="true" labelPosition="before" (change)="toggleAutoRefresh($event)">
                Auto Refresh
            </mat-slide-toggle>
            <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </h1>
    <hr />

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <h3 *ngIf="!loading && getData().length === 0"> No shipping cartons to show.</h3>

    <table *ngIf="!loading && getData().length > 0" mat-table [dataSource]="getData()" class="mat-elevation-z0 table">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="cartonId">
            <th mat-header-cell *matHeaderCellDef> Carton Id </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.cartonId}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="cartonNumber">
            <th mat-header-cell *matHeaderCellDef> Carton # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.cartonNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="cartonType">
            <th mat-header-cell *matHeaderCellDef> Carton Type </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.cartonType}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef> Customer </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.customerName}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="salesOrderNumber">
            <th mat-header-cell *matHeaderCellDef> SO # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.salesOrderNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef> Project </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.projectName}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="apartmentNumber">
            <th mat-header-cell *matHeaderCellDef> Apt # </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.apartmentNumber}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="isAccessoriesOnly">
            <th mat-header-cell *matHeaderCellDef> Accessories Only </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.isAccessoriesOnly | active}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="timeScannedAtStrapper">
            <th mat-header-cell *matHeaderCellDef> Time Scanned </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.timeScannedAtStrapper | date:'medium'}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="shipMethod">
            <th mat-header-cell *matHeaderCellDef> Ship Method </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.shipMethod}}</span>
            </td>
        </ng-container>
    </table>
</mat-card>